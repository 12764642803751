export default {
    state:{
        personInfo:(sessionStorage.getItem("userInfo")!=undefined || sessionStorage.getItem("userInfo")!='') ? JSON.parse(sessionStorage.getItem("userInfo")) :"",
        personDetails:JSON.parse(sessionStorage.getItem("personDetails"))
    },
    mutations:{
        chagePersonData(state,details){
            console.log(details);
            // state.personDetails = details;
            // 数据持久化
            sessionStorage.setItem("personDetails",JSON.stringify(details));
        }
    }
}