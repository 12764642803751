import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)


const router = new Router({
    // mode: 'history', //本地开发
    // === 部署服务器配置开始        ----- 服务器配置 1
    base:'/',
    mode: 'hash',
    // === 部署服务器配置结束
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('./pages/teaHome'),
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@/pages/loginPages")
        },
        {
            path: '/shop',
            name: 'shop',
            component: () => import('./pages/shopIndex'),
        },
        {
            path:"/production",
            name:'production',
            component: () => import('./pages/production'),
        },
        {
            path: '/education',
            name: 'education',
            component: () => import('./pages/educationIndex')
        },
        // 微课 --Lewis
        {
            path: '/videoLesson',
            name: 'videoLesson',
            component: () => import('./pages/videoLessonIndex')
        },
        // 微课-直播 --Lewis
        {
            path: '/videoLessonSeeding',
            name: 'videoLessonSeeding',
            component: () => import('./pages/videoLessonSeeding')
        },
        // 微课-详情页 --Lewis
        {
            path: '/videoLessonDetail',
            name: 'videoLessonDetail',
            component: () => import('./pages/videoLessonDetail')
        },
        // 微课-详情页 --Lewis
        {
            path: '/videoLessonPlay',
            name: 'videoLessonPlay',
            component: () => import('./pages/videoLessonPlay')
        },
        // 文化 --Lewis
        {
            path: '/culture',
            name: 'culture',
            component: () => import('./pages/cultureIndex')
        },
        // 文化鉴赏 --Lewis
        {
            path: '/cultureAppreciate',
            name: 'cultureAppreciate',
            component: () => import('./pages/cultureAppreciate')
        },
        // 文化鉴赏 --Lewis
        {
            path: '/cultureAppreciate1',
            name: 'cultureAppreciate1',
            component: () => import('./pages/cultureAppreciate1')
        },
        // 文化文章 --Lewis
        {
            path: '/culturePage',
            name: 'culturePage',
            component: () => import('./pages/culturePage')
        },
        // 文化文章详情 --Lewis
        {
            path: '/culturePageDetail',
            name: 'culturePageDetail',
            component: () => import('./pages/culturePageDetail')
        },
        {
            path: '/person',
            name: 'person',
            component: () => import('./pages/personDetails'),
            // beforeEnter : (to, from, next) => {
            //     let token = localStorage.getItem('Authorization');
            //     console.log(token); 
            //     if (token === null || token === '' || token==='undefined') {
            //         next('/login');
            //     } else {
            //         next();
            //     }
            
            // }
        },
    ],
})



export default router