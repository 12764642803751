export default{
	state:{
		target1: null
	},
	mutations:{
		setTarget(state,target){
			console.log("target",target)
			state.target = target.target
		}
	}
}