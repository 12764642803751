export default {
    state: {
    // 存储token
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    isLogin:sessionStorage.getItem('isLogin') ? sessionStorage.getItem('isLogin') : '',
    // userInfo: localStorage.getItem('userInfo') ? localStorage.getItem('userInfo') : ''
    // personInfo:(sessionStorage.getItem("userInfo")!=undefined || sessionStorage.getItem("userInfo")!='')? JSON.parse(sessionStorage.getItem("userInfo")) :""
  },
 
  mutations: {
    // 修改token，并将token存入localStorage
    changeLogin (state, LoginInfo) {
      // console.log('state',state);
      console.log('user',LoginInfo.Authorization);
      state.Authorization = LoginInfo.Authorization;

      sessionStorage.setItem("isLogin",true);
      localStorage.setItem('Authorization', LoginInfo.Authorization);
    },
    changeUserInfo(state,user){
      let userInfo = user.userInfo
      console.log("userInfo",JSON.stringify(userInfo));
      sessionStorage.setItem("userInfo",JSON.stringify(userInfo))
    }
  }
};