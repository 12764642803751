import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import env from './env';
import VueAxios from 'vue-axios';
import axios from 'axios'
import store from './store/index'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import '/src/assets/js/noScale.js'
//import animated
// import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
import echarts from 'echarts'

// import ECharts from 'vue-echarts'
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, { ak: 'zaONWSBT7SvWX7kfBLZNvI5450RRPyAc' })


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueAxios, axios)
// Vue.use(animated)  //----部署服务器上需要去除
Vue.prototype.$wow = wow
// Vue.component('v-chart', ECharts)



//跨域请求设置
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
  
// 拦截器
axios.interceptors.request.use(
  config => {
    if(store.state.token){
      config.headers.common['token'] = store.state.token.token
    }
    return config;
  },
  error => {
    //请求错误
    return Promise.reject(error);
  }
);
//添加响应拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(error.response){
      switch(error.response.status){
        case 401:
          localStorage.removeItem('token');
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath //登录成功后跳入浏览的当前页
            }
          })
      }
    }
  }
)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// Vue.prototype.$echarts = echarts
// new wow({
//   live: false
// });

router.afterEach(() => {
  window.scrollTo(0, 0)
})
