import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import login from './login'
import person from './person'
import tesHu from "@/store/tesHu";
Vue.use(Vuex)

// 创建vuex实例
export default new Vuex.Store({
    modules:{
        tab,
        login,
        person,
        tesHu
    }
})